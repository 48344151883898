@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth !important;
  overflow-x: hidden;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
